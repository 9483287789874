












































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextarea from '@/common/components/STextarea.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'Chem1301Lab3Calculations',
  components: {CalculationInput, STextarea, TableInput, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        step18Calc: null,
        step20Calc: null,
        step32Calc1: null,
        step32Calc2: null,
        step36Calc1: null,
        step36Calc2: null,
      },
      rows: [
        {
          step: '18',
          input: 'step18Calc',
          calc: true,
          question: 'What is the pH of a 0.1 M solution of HCl?',
        },
        {
          step: '20',
          input: 'step20Calc',
          calc: true,
          question: 'What is the pH of a 0.1 M solution of NaOH?',
        },
        {
          step: '32 a)',
          input: 'step32Calc1',
          calc: true,
          question:
            'Calculate the concentration of the strong acid diluting it (assuming you added exactly 1 mL of the acid to 20 mL of water)?',
        },
        {
          step: '32 b)',
          input: 'step32Calc2',
          calc: true,
          question: 'Calculate the expected pH of the diluted acid solution.',
        },
        {
          step: '36 a)',
          input: 'step36Calc1',
          calc: true,
          question:
            'Calculate the concentration of the strong base diluting it (assuming you added exactly 1 mL of the base to 20 mL of water)?',
        },
        {
          step: '36 b)',
          input: 'step36Calc2',
          calc: true,
          question: 'Calculate the expected pH of the diluted base solution.',
        },
      ],
    };
  },
});
